import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import { useABTestIntegration } from 'lib/ab-tests/hooks/use-ab-test-integration';
import { getTestVariant } from 'lib/ab-tests/selectors';
import { useSearchParams } from 'lib/search/hooks/query/use-search';
import { getSearchRequestFormattedArea } from 'lib/search/selectors';
import { getIsCountrySearch } from 'lib/search/utils/get-is-country-search';
import { IApplicationState } from 'lib/types';
import { useSelector } from 'lib/utils';

const testId = 'LIVE-20805_BrowsePresetsInSearchPage';

// Hook with condition to trigger test
export const useShouldTriggerBrowsePresetsTest = () => {
  const market = useMarket();
  const searchParams = useSearchParams();
  const formattedArea = useSelector(getSearchRequestFormattedArea) || '';

  const isCountrySearch = getIsCountrySearch(formattedArea);

  const searchCountryCode = searchParams?.market.country;
  const weddingCountryCode = market.country;
  const isMonetizedWeddingMarket = market.flags.monetized;

  return (
    searchParams?.slug === 'venues' &&
    isCountrySearch &&
    isMonetizedWeddingMarket &&
    weddingCountryCode === searchCountryCode
  );
};

/**
 * Returns the active variant of the test for use in selectors, components etc.
 * If the test is not activated, it returns 'control'
 */
export const getVariant_BrowsePresetsInSearchPage = (state: IApplicationState) =>
  getTestVariant(state, testId);

/**
 * Hook for initializing the test
 */
export const useABTest_BrowsePresetsInSearchPage = () => {
  const triggerCondition = useShouldTriggerBrowsePresetsTest();

  useABTestIntegration(testId, triggerCondition);
};
