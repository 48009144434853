import { pathOr } from 'ramda';
import { Weddings } from '@bridebook/models';
import { PartialRecursive } from '@bridebook/models/source/abstract/_';
import { IWedding } from '@bridebook/models/source/models/Weddings.types';
import { IPreference } from '@bridebook/models/source/models/Weddings/Preferences.types';
import {
  IPreferenceCakes_DietaryOptions,
  IPreferenceCakes_FlavourOptions,
} from '@bridebook/models/source/models/Weddings/Preferences/cakes.types';
import { IPreferenceFlorist_Style } from '@bridebook/models/source/models/Weddings/Preferences/florist.types';
import { IPreferencePhoto_Style } from '@bridebook/models/source/models/Weddings/Preferences/photo.types';
import { IPreferenceVenue_MustHaves } from '@bridebook/models/source/models/Weddings/Preferences/venue.types';
import { IPreferenceVideo_Style } from '@bridebook/models/source/models/Weddings/Preferences/video.types';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { setEnquiryFormField } from 'lib/enquiries/actions';
import { IDeps } from 'lib/types';
import { UserActionTypes } from 'lib/users/action-types';
import { getCurrentUserId } from 'lib/users/selectors';
import { WeddingActionTypes } from 'lib/weddings/action-types';
import { ILatLongBounds } from 'lib/weddings/types';

export const weddingProfileSaveDate = (datePickerId: 'weddingDate' | 'engagementDate') => ({
  type: WeddingActionTypes.SAVE_WEDDING_DATE,
  payload: { datePickerId },
});

export const changeWeddingLocationAnalytics = (countryCode: string) => ({
  type: WeddingActionTypes.UPDATED_WEDDING_LOCATION_ANALYTICS,
  payload: { countryCode },
});

export const selectPremiumPricingOption = ({
  plan,
  category,
}: {
  plan: string;
  category: string;
}) => ({
  type: WeddingActionTypes.SELECT_PREMIUM_PRICING_OPTION,
  payload: { plan, category },
});

export const changeCurrency =
  ({ value }: { value: string }) =>
  ({ getState, dispatch }: IDeps) => {
    const { id } = getState().weddings.profile;
    Weddings._.getById(id)
      .set({
        l10n: { currency: value },
      })
      .then(() =>
        dispatch({
          type: 'EDITED_WEDDING_DETAILS_ANALYTICS',
          payload: { name: 'currency' },
        }),
      );

    return {
      type: WeddingActionTypes.UPDATE_CURRENCY,
    };
  };

export const saveVenueLocation =
  (location: IWedding['location'], custom = false) =>
  ({ dispatch }: IDeps) => {
    if (!custom) dispatch(updateWeddingField('location', { location }));
    dispatch(setEnquiryFormField('location', location));

    return {
      type: UserActionTypes.SAVE_VENUE_LOCATION,
    };
  };

export const fetchCountryBoundsStart = (countryCode: string) => ({
  type: WeddingActionTypes.FETCH_PROFILE_COUNTRY_BOUNDS_START,
  payload: countryCode,
});

export const fetchCountryBoundsSuccess = (latLongBounds: ILatLongBounds) => ({
  type: WeddingActionTypes.FETCH_PROFILE_COUNTRY_BOUNDS_SUCCESS,
  payload: latLongBounds,
});

export const updateWedding =
  (payload: IWedding) =>
  ({ getState, dispatch }: IDeps) => {
    const state = getState();
    const omitUpdateEnquiryForm = state.enquiries.showEnquiryRequiredData;
    const userId = getCurrentUserId(state);
    if (!userId) {
      throw new Error('User does not exist in state');
    }

    // @ts-expect-error TODO: This should be always CountryCodes
    const prevCountry: CountryCodes | undefined = pathOr(
      '',
      ['l10n', 'country'],
      state.weddings.profile,
    );
    if (prevCountry !== payload.l10n.country) {
      dispatch(fetchCountryBoundsStart(payload.l10n.country));
    }

    return {
      type: WeddingActionTypes.UPDATE_WEDDING,
      payload: {
        wedding: payload,
        omitUpdateEnquiryForm,
      },
    };
  };

export const updateWeddingField = (
  name: keyof IWedding,
  value: PartialRecursive<IWedding>,
  extraAnalytics?: Record<string, any>,
) => ({
  type: WeddingActionTypes.UPDATE_WEDDING_FIELD,
  payload: { name, value, extraAnalytics },
});

export const pbStatusAnalytics = (pbStatus: boolean) => ({
  type: 'PB_STATUS_ANALYTICS',
  payload: { pbStatus },
});

export const updateWeddingPreferences = (
  id: IPreference['id'],
  property: 'dietaryOptions' | 'flavourOptions' | 'style' | 'mustHaves',
  value:
    | IPreferenceCakes_DietaryOptions[]
    | IPreferenceCakes_FlavourOptions[]
    | IPreferenceFlorist_Style[]
    | IPreferenceVenue_MustHaves[]
    | IPreferencePhoto_Style[]
    | IPreferenceVideo_Style[],
) => ({
  type: WeddingActionTypes.UPDATE_WEDDING_PREFERENCES,
  payload: { id, property, value },
});

export const updatePartnerNames = (payload: [string, string]) => ({
  type: WeddingActionTypes.UPDATE_PARTNER_NAMES,
  payload,
});
