import { getTestVariant } from 'lib/ab-tests/selectors';
import { ABTestData } from 'lib/ab-tests/types';
import { IApplicationState } from 'lib/types';
import { useABTestIntegration } from '../../hooks/use-ab-test-integration';

const testData: ABTestData = {
  id: 'LIVE-19024_PhotoQuizSearchIntercept',
  name: 'LIVE-19024 PhotoQuizSearchIntercept',
  description: 'Intercept search redirect to display photo quiz',
};

export const getIsPhotoQuizSearchInterceptEnabled = (state: IApplicationState) =>
  getTestVariant(state, testData.id) !== 'control';

export const useABTest_PhotoQuizSearchIntercept = () => {
  const triggerCondition = true;

  useABTestIntegration(testData.id, triggerCondition);
};
