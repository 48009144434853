import { useABTestIntegration } from 'lib/ab-tests/hooks/use-ab-test-integration';
import { getTestVariant } from 'lib/ab-tests/selectors';
import { IApplicationState } from 'lib/types';

const testId = 'LIVE-20786_Map30';

/**
 * Returns the active variant of the test for use in selectors, components etc.
 * If the test is not activated, it returns 'control'
 */
export const getVariant_Map30 = (state: IApplicationState) => getTestVariant(state, testId);

export const getHasNewMap30 = (state: IApplicationState) => {
  const testVariant = getTestVariant(state, testId);

  return testVariant === '1' || testVariant === '2';
};

/**
 * Hook for initializing the test
 */
export const useABTest_Map30 = () => {
  // If needed, specify some additional trigger conditions
  // which must be true to start the test
  const triggerCondition = true;

  useABTestIntegration(testId, triggerCondition);
};
