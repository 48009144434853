import { getTestVariant } from 'lib/ab-tests/selectors';
import { ABTestData } from 'lib/ab-tests/types';
import { IApplicationState } from 'lib/types';
import { useABTestIntegration } from '../../hooks/use-ab-test-integration';

const testData: ABTestData = {
  id: 'LIVE-19024_HairAndBeautyQuizSearchIntercept',
  name: 'LIVE-19024 HairAndBeautyQuizSearchIntercept',
  description: 'Intercept search redirect to display hair and beauty quiz',
};

export const getIsHairAndBeautyQuizSearchInterceptEnabled = (state: IApplicationState) =>
  getTestVariant(state, testData.id) !== 'control';

export const useABTest_HairAndBeautyQuizSearchIntercept = () => {
  const triggerCondition = true;

  useABTestIntegration(testData.id, triggerCondition);
};
