import { IApplicationState, INextAction } from 'lib/types';

export const selectNextAction = (
  state: IApplicationState,
  type: INextAction['type'],
  id: INextAction['id'],
) => {
  const nextAction = state.ui.nextAction;
  if (nextAction?.type !== type || nextAction?.id !== id) return undefined;

  return nextAction;
};

export const getIsLostLeadModalVisible = (state: IApplicationState) => state.ui.lostLeadModal.show;
export const getLostLeadModalSupplierId = (state: IApplicationState) =>
  state.ui.lostLeadModal.supplierId;
export const getMobileNavShown = (state: IApplicationState) => state.ui.mobileNavShown;

export const getIsRatingModalVisible = (state: IApplicationState) => state.ui.isRatingModalVisible;
