import { colors } from '../../../themes/variables';

interface IStyleProps {
  theme: string;
}

export const messageContainerStyle = ({ theme }: IStyleProps) => {
  const themeColor = () => {
    const blueCrush = {
      primary: colors.blueCrush,
      lighten: colors.blueCrush10,
    };

    switch (theme) {
      case 'benefit':
        return blueCrush;
      case 'open':
        return {
          primary: colors.yellow,
          lighten: colors.yellow10,
        };
      case 'related':
        return {
          primary: colors.mint,
          lighten: colors.mint10,
        };
      case 'alert':
        return {
          primary: colors.amber,
          lighten: colors.amber10,
        };
      case 'mega-alert':
        return {
          primary: colors.amber,
          lighten: colors.amber,
        };
      default:
        return blueCrush;
    }
  };

  return {
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingTop: '6px',
    paddingBottom: '4px',
    fontDefault: 16,
    lineHeight: '20px',
    borderBottom: `2px solid ${themeColor().primary}`,
    backgroundColor: themeColor().lighten,
  };
};
