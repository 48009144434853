import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import { useABTestIntegration } from 'lib/ab-tests/hooks/use-ab-test-integration';
import { getTestVariant } from 'lib/ab-tests/selectors';
import { usePageSettings } from 'lib/page-settings-context';
import { useSearchParams } from 'lib/search/hooks/query/use-search';
import { IApplicationState } from 'lib/types';

const testId = 'LIVE-21226_WeddingEstimatePriceFilter';

export const countryWithWeddingEstimatePriceFilter = CountryCodes.GB;

// Hook with condition to trigger test
export const useShouldTriggerWeddingEstimatePriceFilterTest = (supplier?: ISupplier) => {
  const market = useMarket();
  const searchParams = useSearchParams();
  const { isSearchPage, isSupplierPage } = usePageSettings();
  const supplierCountryCode = supplier?.l10n?.country;

  const searchCountryCode = searchParams?.market.country;
  const weddingCountryCode = market.country;

  const isCoupleCountrySameAsSearchOrSupplier = isSearchPage
    ? weddingCountryCode === searchCountryCode
    : isSupplierPage && !!supplier
    ? weddingCountryCode === supplierCountryCode
    : false;

  const isVenue = isSearchPage
    ? searchParams?.slug === 'venues'
    : isSupplierPage && !!supplier
    ? supplier?.type.includes('venue')
    : false;

  // Test available only for venue search and UK
  return (
    isVenue &&
    countryWithWeddingEstimatePriceFilter === weddingCountryCode &&
    isCoupleCountrySameAsSearchOrSupplier
  );
};

/**
 * Returns the active variant of the test for use in selectors, components etc.
 * If the test is not activated, it returns 'control'
 */
export const getVariant_WeddingEstimatePriceFilter = (state: IApplicationState) =>
  getTestVariant(state, testId);

/**
 * Hook for initializing the test
 */
export const useABTest_WeddingEstimatePriceFilter = (supplier?: ISupplier) => {
  const triggerCondition = useShouldTriggerWeddingEstimatePriceFilterTest(supplier);

  useABTestIntegration(testId, triggerCondition);
};
