import { FirebaseApp, initializeApp } from 'firebase/app';
import { browserSessionPersistence, getAuth, setPersistence } from 'firebase/auth';
import {
  CACHE_SIZE_UNLIMITED,
  Timestamp,
  enableMultiTabIndexedDbPersistence,
  getFirestore,
  initializeFirestore,
  serverTimestamp,
  setLogLevel,
} from 'firebase/firestore';
import { Cards } from '@bridebook/models/source/models/Countries/Cards';
import { getIsE2E } from '@bridebook/toolbox/src/e2e/get-is-e2e';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import { AutocompletePredictionsService } from 'app-shared/lib/search/utils/autocomplete-predictions-service';
import { CustomPredictionService } from 'app-shared/lib/search/utils/custom-autocomplete-predictions/custom-prediction-service';
import { GooglePlacesService } from 'app-shared/lib/search/utils/google-places-service';
import { env } from 'lib/env';
import { FeatureCardsService } from 'lib/feature-cards/feature-cards-service';
import { CordovaTrackerService } from 'lib/track-utils/services/cordova-tracker-service';
import { MuteActionsService } from 'lib/utils/mute-actions-service';
import { WarningModalService } from 'lib/utils/warning-modal-service';
import validate from './validate';

const firebaseConfig = env.FIREBASE;

const getFirebaseDeps = (firebaseApp: FirebaseApp) => ({
  firebaseApp,
  serverTimestamp: serverTimestamp() as Timestamp,
});

export type IFirebaseDeps = ReturnType<typeof getFirebaseDeps>;

// Ensure only one Firebase instance.
let firebaseDeps: IFirebaseDeps | null = null;

const createFirebaseDeps = () => {
  if (!firebaseDeps && process.browser) {
    const firebaseApp = initializeApp(firebaseConfig);
    firebaseDeps = getFirebaseDeps(firebaseApp);

    initializeFirestore(firebaseApp, {
      cacheSizeBytes: CACHE_SIZE_UNLIMITED,
    });

    // When running inside a E2E test, the authentication information is saved
    // and retrieved from the session storage, instead of using the default
    // persistence in the browser IndexDB. This approach enables saving the
    // authentication data into a JSON file and reusing it between tests, which
    // is faster and doesn't hit the Firebase quota of logins.
    if (getIsE2E()) {
      const auth = getAuth();
      setPersistence(auth, browserSessionPersistence);
    } else {
      const db = getFirestore(firebaseApp);

      enableMultiTabIndexedDbPersistence(db).catch((error) => {
        switch (error.code) {
          case 'failed-precondition':
            SentryMinimal().captureMessage(
              '[Auth-context] IndexedDB persistence failed because multiple tabs are open',
              {
                extra: { error },
              },
            );
            break;
          case 'unimplemented':
            SentryMinimal().captureMessage(
              '[Auth-context] IndexedDB persistence is not supported',
              {
                extra: { error },
              },
            );
            break;
          default:
            SentryMinimal().captureMessage('[Auth-context] IndexedDB persistence error', {
              extra: { error },
            });
            break;
        }
      });
    }

    setLogLevel('error');
  }
  return firebaseDeps;
};

const fbDeps = createFirebaseDeps();
export const getFirebaseApp = () => fbDeps?.firebaseApp;

const muteActions = new MuteActionsService();
const warningModal = new WarningModalService(muteActions);
const cordovaTracker = new CordovaTrackerService(muteActions);
const googlePlacesService = new GooglePlacesService();
const autocompletePredictionsService = new AutocompletePredictionsService(
  new CustomPredictionService(),
  googlePlacesService,
);

const featureCardsService = new FeatureCardsService(Cards);

const configureDeps = () => ({
  ...fbDeps,
  validate,
  firebaseUrl: firebaseConfig.databaseURL,
  cordovaTracker,
  muteActions,
  googlePlacesService,
  autocompletePredictionsService,
  featureCardsService,
  warningModal,
});

export type IConfigureDeps = ReturnType<typeof configureDeps>;

export default configureDeps;
