import { mapDateFromUI } from '@bridebook/toolbox';
import { mapDateToIsoFormat } from '@bridebook/toolbox/src/datepicker/map-date-to-from-ui';
import { IDatePickerUI } from '@bridebook/toolbox/src/datepicker/types';
import { IUISupplier } from '@bridebook/toolbox/src/types';
import { saveWeddingDate } from 'lib/datepicker/actions';
import {
  bookedSupplierAnalytics,
  createCustomSupplier,
  saveToShortlist,
  toggleCustomSupplierFormModal,
} from 'lib/shortlist/actions';
import {
  BookedMethod,
  IDeps,
  TUIShortlistSupplier,
  VenueConfirmSlides,
  VenueFoundAnswers,
} from 'lib/types';
import { completedVenueSourceStepAnalytics } from 'lib/venue-confirmation/analytics/actions';
import { VenueConfirmActionTypes } from './action-types';

export const triggerBookingConfirmationModal = ({
  supplier = null,
  showIntro,
  location,
  searchSupplierType,
}: {
  supplier?: IUISupplier | null;
  showIntro?: boolean;
  location: string;
  searchSupplierType?: IUISupplier['type'];
}) => ({
  type: VenueConfirmActionTypes.TRIGGER_BOOKING_CONFIRMATION_MODAL,
  payload: { supplier, showIntro, location, searchSupplierType },
});

export const closeBookingConfirmationModal = () => ({
  type: VenueConfirmActionTypes.CLOSE_BOOKING_CONFIRMATION_MODAL,
});

export const venueBookedToggle = (value: VenueFoundAnswers) => ({
  type: VenueConfirmActionTypes.VENUE_BOOKED_TOGGLE,
  payload: value,
});

export const venueConfirmChangeSlide = (slideName: VenueConfirmSlides) => ({
  type: VenueConfirmActionTypes.CHANGE_SLIDE,
  payload: slideName,
});

export const venueConfirmSaveVenueInState = (venue: IUISupplier) => ({
  type: VenueConfirmActionTypes.SAVE_VENUE_TO_BOOK,
  payload: venue,
});

export const saveVenueEstimate = (price: number) => ({
  type: VenueConfirmActionTypes.SAVE_VENUE_PRICE,
  payload: price,
});

export const saveWeddingYear = (year: number) => ({
  type: VenueConfirmActionTypes.SAVE_WEDDING_YEAR,
  payload: year,
});

export const saveWeddingMonth = (month: number) => ({
  type: VenueConfirmActionTypes.SAVE_WEDDING_MONTH,
  payload: month,
});

export const triggerSupplierInterestConfirmation = () => ({
  type: VenueConfirmActionTypes.TRIGGER_SUPPLIER_CONFIRMATION,
});

export const saveSupplierIdToConfirm = (payload: string | null) => ({
  type: VenueConfirmActionTypes.SAVE_SUPPLIER_ID_TO_CONFIRM,
  payload,
});

export const confirmSupplierInterest = (payload: TUIShortlistSupplier) => ({
  type: VenueConfirmActionTypes.CONFIRM_SUPPLIER_INTEREST,
  payload,
});

export const denySupplierInterest = (payload: TUIShortlistSupplier) => ({
  type: VenueConfirmActionTypes.DENY_SUPPLIER_INTEREST,
  payload,
});

/**
 * Complete venue select step
 */
export const completeVenueSelectStep =
  (date: IDatePickerUI) =>
  ({ dispatch, getState }: IDeps) => {
    const { venueToBook, triggerLocation } = getState().venueConfirmation;

    if (venueToBook) {
      if (venueToBook.customSupplier) {
        dispatch(createCustomSupplier('confirmVenuePopup', venueToBook, true, 'shortlist', false));
        dispatch(toggleCustomSupplierFormModal(false, true, 'venueConfirmationPopup'));
      }
      dispatch(
        saveToShortlist(
          { ...venueToBook, booked: true },
          'confirmVenuePopup',
          'bookingConfirmationPopup',
          false,
        ),
      );

      dispatch(
        bookedSupplierAnalytics({
          supplier: venueToBook,
          method: triggerLocation as BookedMethod,
        }),
      );
      if (date) {
        dispatch(saveWeddingDate(date));
      }
      const weddingDate = mapDateToIsoFormat(mapDateFromUI(date));
      dispatch(closeBookingConfirmationModal());

      return {
        type: VenueConfirmActionTypes.CONFIRM_VENUE,
        payload: { bookedVenue: venueToBook, weddingDate },
      };
    } else {
      return triggerSupplierInterestConfirmation();
    }
  };

/**
 * Complete venue source step
 */
export const completeVenueSourceStep =
  (discoverSource: string) =>
  ({ dispatch }: IDeps) => {
    // Analytics
    dispatch(completedVenueSourceStepAnalytics(discoverSource));

    return venueConfirmChangeSlide(VenueConfirmSlides.weddingMonth);
  };

/**
 * Complete datepicker step
 */
export const confirmVenue =
  (date: IDatePickerUI) =>
  ({ dispatch, getState }: IDeps) => {
    const bookedVenue = getState().venueConfirmation.venueToBook;
    if (!bookedVenue) throw Error('Confirm venue cannot be called without venue to book');

    if (date) {
      dispatch(saveWeddingDate(date));
    }

    const weddingDate = mapDateToIsoFormat(mapDateFromUI(date));

    dispatch(closeBookingConfirmationModal());
    return { type: VenueConfirmActionTypes.CONFIRM_VENUE, payload: { bookedVenue, weddingDate } };
  };
