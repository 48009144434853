import { FelaCSS } from '../../../components/fela/flowtypes';
import colorParse from '../../../fela-utils/color-parse';
import { colors } from '../../../themes/variables';
import { SharedTypes } from './inline-tag';

interface StyleTypes {
  wrapper: FelaCSS;
}

const styles = ({ small, medium, backgroundColor, color }: SharedTypes): StyleTypes => {
  const fontColor = color ? colorParse(color) : colors.white;
  const bgColor = backgroundColor ? colorParse(backgroundColor) : colors.mint120;

  const statePaddings: FelaCSS = small
    ? {
        paddingLeft: '5px',
        paddingRight: '5px',
        paddingTop: '0',
        paddingBottom: '0',
      }
    : {
        paddingLeft: '4px',
        paddingRight: '4px',
        paddingTop: '2px',
        paddingBottom: '4px',
      };

  const paddings: FelaCSS =
    small || medium
      ? statePaddings
      : {
          paddingLeft: '4px',
          paddingRight: '4px',
          paddingTop: '1px',
          paddingBottom: '1px',
        };

  return {
    wrapper: {
      ...paddings,
      display: 'inline-flex',
      justifyContent: 'center',
      height: small ? '15px' : 'auto',
      fontDefaultSemiBold: medium || small ? 10 : 12,
      lineHeight: small ? '11px' : '1em',
      textTransform: 'uppercase',
      color: fontColor,
      borderRadius: '100px',
      backgroundColor: bgColor,
    },
  };
};

export default styles;
